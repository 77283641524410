<template>
    <main>
        <input :disabled="disabled" v-model="val"  type="number" class="form-control">
    </main>
</template>
<script>
export default {
    props: ['value','validado'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        }
    },
    computed:{
        disabled:function(){
            if(this.validado===1){
                return true;
            }
            return false
        }
    }
}
</script>


