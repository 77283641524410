<template>
    <main>
        <page-header-compact>Resultados de éxamen de laboratorio</page-header-compact>
        <div class="container-fluid px-4">
            <div class="card card-icon mb-2">
                <div class="row no-gutters">
                    <div class="col-auto card-icon-aside bg-light">
                        <i class="fa fa-flask fa-fw"></i>
                    </div>
                    <div class="col">
                        <div class="card-body">
                           <div class="row">
                                <div class="col-lg-8">
                                    <div class="form-row">
                                        <label class="form-label">Fecha</label>
                                        <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="fecha"
                                        :class="{
                                            'is-invalid':
                                            $v.fecha.$error && $v.fecha.$dirty,
                                        }"
                                    />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-row mt-4 py-2">
                                        <button class=" btn btn-success btn-sm" @click="cargarResultadosExamenes();"><i class="fa fa-search fa-fw"></i>Buscar</button>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="alert alert-warning" v-if="Object.keys(pagination.data).length && !pagination.data.length">No hay resultados...</div> 
            
            <div class="card">
                <div class="card-body">
                    <data-grid :data="pagination" :search-box="false" @search="index(pagination)" >
                        <template #grid>
                        <!-- 
                            <div class="d-flex justify-content-between">
                                <input class="form-control form-control-sm form-control-solid mb-2" v-model="pagination.search"
                                type="text" aria-label="Search" />
                                <button class="btn btn-sm btn-info shadow-sm ml-2 mb-2" title="Buscar" @click="search()">
                                    <i class="fas fa-search text-white-50"></i>
                                </button>
                            </div>
                        -->



                            <div v-for="item in pagination.data" :key="item.id">

                              


                                <div class="card card-icon mb-2 shadow-none mt-2">
                                    <div class="row no-gutters">
                                        <div class="text-center col-auto card-icon-aside bg-light" v-if="item.orden_trabajo.usuario.sexo === 'F'">
                                            <img class="" src="@/assets/img-genero/mujer.png" width="96">
                                        </div>
                                        <div class="text-center col-auto card-icon-aside bg-light" v-else-if="item.orden_trabajo.usuario.sexo === 'M'">
                                            <img class="" src="@/assets/img-genero/hombre.png" width="64">
                                        </div>
                                        <div class="col">
                                            <div class="card-body py-2 mt-2">
                                                <h5>Nombre del paciente : <strong>{{item.orden_trabajo.usuario.nombre_1}} {{item.orden_trabajo.usuario.nombre_2}} {{item.orden_trabajo.usuario.apellido_1}} {{item.orden_trabajo.usuario.apellido_2}}</strong> </h5>
                                                <h5>Identificación : <strong>{{item.orden_trabajo.usuario.documento}}</strong> </h5>
                                                <h5>Edad : <strong>{{item.orden_trabajo.usuario.years}}</strong> </h5> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-12"  v-for="detalle in item.detalles" :key="detalle.id">
                                        <div class="card card-icon mb-2 shadow-none mt-2" >
                                            <div class="row no-gutters">
                                                <div class="col-auto card-icon-aside bg-warning-soft" v-show="detalle.validado===0">
                                                    <i class="fas fa-exclamation-triangle text-warning" :key="`${detalle.id}_val`"></i>
                                                </div>
                                                <div class="col-auto card-icon-aside bg-success-soft text-white-50" v-show="detalle.validado===1">
                                                    <i class="fas fa-check-double fa-fw text-success" :key="`${detalle.id}_inval`"></i>
                                                </div>
                                                <div class="col">
                                                    <div class="card-body py-2">
                                                        <div class="form-group" >
                                                            <div>
                                                                <label class="form-label small">{{ detalle.examenes.nombre }}</label>
                                                            </div>
                                                            <div> 
                                                                <label class="form-label small">Valor de referencia : <strong>{{detalle.examenes.valores_referencia}} </strong></label>
                                                            </div>

                                                            
                                                           <!-- 
                                                            <input type="text" class="form-control form-control-sm"   v-model="examen.resultado">
                                                           -->
                                                           
                                                            
                                                            <texto
                                                                :validado="detalle.validado"
                                                                v-model="detalle.resultado"
                                                                v-if="detalle.examenes.tipo === 'texto'"
                                                            ></texto>

                                                            <numerico
                                                                :validado="detalle.validado"
                                                                v-model="detalle.resultado"
                                                                v-if="detalle.examenes.tipo === 'numero'"
                                                            ></numerico>
                                                            
                                                            <opciones
                                                                :validado="detalle.validado"
                                                                :examen="detalle.examenes"
                                                                v-model="detalle.resultado"
                                                                v-if="detalle.examenes.tipo === 'opcion'"
                                                            ></opciones> 
                                                        </div>
                                                        <div class="form-group ">
                                                            <div v-if="detalle.validado===0">
                                                                <span class="badge badge-warning mx-2 badge-marketing text-warning-soft">Sin validar</span>
                                                                <button class="btn btn-success btn-xs text-success-soft shadow-sm" @click="validarExamen(detalle)">
                                                                    <i class="fa fa-check fa-fw"></i>Validar
                                                                </button>
                                                            </div>
                                                            <span class="badge badge-success text-success-soft shadow-sm badge-marketing" v-else>Validado</span>
                                                            
                                                        </div>      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </data-grid>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import resultadosLaboratorioService from '../../../../../services/resultadosLaboratorioService';
import Toast from "../../../../../components/common/utilities/toast";
import DataGrid from "../../../../../components/common/utilities/DataGrid/DataGrid";
import paginateMixin from "../../../../../mixins/PaginateMixin";
import texto from "../../../../../views/historias/base/resultadosLaboratorios/tipos/texto";
import numerico from "../../../../../views/historias/base/resultadosLaboratorios/tipos/numerico";
import opciones from "../../../../../views/historias/base/resultadosLaboratorios/tipos/opciones";
import { required } from "vuelidate/lib/validators";

export default {
    components: {
        PageHeaderCompact,DataGrid,
        texto,numerico,opciones
    },
        mixins: [paginateMixin(async (search='',pagination={})=>resultadosLaboratorioService.index(search,pagination))],
    data() {
        return {
            Isdisabled:false,
            resultadosExamenes:[],
            fecha:'',
            id_profesional:'',
            pagination: {
                per_page:1,
                params: {
                    id_profesional:'',
                    fecha:''
                }
            },
        }
    },
    validations() {
      return {
        fecha: {required},
      };
    },
    methods: {
        async cargarResultadosExamenes(){
            try {
                

                this.$v.fecha.$touch();
           
                if (this.$v.fecha.$invalid) {
                    return;
                }

                this.LoaderSpinnerShow();
                
                this.pagination.params.fecha= this.fecha;
                this.pagination.params.id_profesional= this.id_profesional;
                const response = await resultadosLaboratorioService.index(this.pagination.search,this.pagination);
                this.resultadosExamenes= response.data;
                this.pagination.data = response.data.data;
                this.pagination.current_page  = response.data.current_page;
                this.pagination.total = response.data.total;
                this.LoaderSpinnerHide();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al procesar la solicitud",
                });
            }
        },
        async validarExamen(detalle){
            try {
                await resultadosLaboratorioService.cambiarEstado(detalle.id, detalle.resultado);
                detalle.validado = 1;
                Toast.fire({
                    icon: "success",
                    title: "Examen validado con éxito",
                });
            } catch (error) {
                console.error(error);
           
                Toast.fire({
                    icon: "error",
                    title: "Ha ocurrido un error al validar el examen",
                });
            }
        },
    },   
}
</script>
