<template>
    <main>
        <select :disabled="disabled" v-model="val"  class="form-control">
            <option  v-for="opcion in examen.opciones" :key="opcion.id" :value="opcion.descripcion">
                {{opcion.descripcion}}
            </option>
        </select>
    </main>
</template>
<script>
export default {
    props: ['examen','value','validado'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        }
    },
    computed:{
        disabled:function(){
            if(this.validado===1){
                return true;
            }
            return false
        }
    }
}
</script>


