import Service from "./Service";
import {isEmpty} from 'lodash';

const baseurl = '/api/resultado-laboratorio';
export default {
    index(search = '', pagination = {}){
       
        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(isEmpty(pagination['per_page']) ? {per_page : pagination['per_page']} : {}),
            ...(isEmpty(pagination['page']) ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };
        console.log(params);
        return Service.get(`${baseurl}`, {
            params : {
                ...params
            }
        });
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    async list(search = '',pagination = {}) {
        let url = `${baseurl}/listar?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    showByOrdenTrabajo(id) {
        return Service.get(`${baseurl}/showByOrdenTrabajo/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    cambiarEstado(id, resultado) {
        return Service.put(`${baseurl}/cambiarEstado/${id}`, { resultado });
    }
    
    


}